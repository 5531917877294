import React from 'react';
import { Container, Typography, Grid, Paper } from '@mui/material';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ChatIcon from '@mui/icons-material/Chat';
import SecurityIcon from '@mui/icons-material/Security';




const Features = () => {
  return (
    <Container>
      <Typography variant="h4" mt={4}>
        Features
      </Typography>
      <Typography variant="body1" mt={2}>
        Discover the powerful features that make BlueCollar Hub the ultimate platform for connecting with skilled artisans:
      </Typography>

      <Grid container spacing={3}>
        {/* Feature 1 */}
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={{ padding: 2, textAlign: 'center' }}>
            <Typography variant="h6" mt={3}>
              Find Skilled Artisans
            </Typography>
            <FindInPageIcon sx={{ fontSize: 80, mt: 1, mb: 2 }} />
          </Paper>
        </Grid>

        {/* Feature 2 */}
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={{ padding: 2, textAlign: 'center' }}>
            <Typography variant="h6" mt={3}>
              Book Appointments
            </Typography>
            <CalendarTodayIcon sx={{ fontSize: 80, mt: 1, mb: 2 }} />
          </Paper>
        </Grid>

        {/* Feature 3 */}
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={{ padding: 2, textAlign: 'center' }}>
            <Typography variant="h6" mt={3}>
              Instant Messaging
            </Typography>
            <ChatIcon sx={{ fontSize: 80, mt: 1, mb: 2 }} />
          </Paper>
        </Grid>

        {/* Feature 4 */}
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={{ padding: 2, textAlign: 'center' }}>
            <Typography variant="h6" mt={3}>
              Secure Transactions
            </Typography>
            <SecurityIcon sx={{ fontSize: 80, mt: 1, mb: 2 }} />
          </Paper>
        </Grid>
      </Grid>

      {/* Additional Information */}
      <Typography variant="body1" mt={3}>
        BlueCollar Hub offers a range of features to enhance your experience, from finding the right artisan to secure and convenient transactions.
      </Typography>
    </Container>
  );
};

export default Features;
