import React from 'react';
import { Typography, Button, Container, CssBaseline, Paper, Grid } from '@mui/material';
import HowItWorks from './HowItWorks';
import Features from './Features';
import Footer from './Footer';
import Navbar from './Navbar';





const Home = () => {
    return (
        <div>
            <CssBaseline />
            <Navbar/>

            {/* Banner */}
            <Grid container>
                {/* Left Section with Text and Caption */}
                <Grid item xs={12} sm={6}>
                    <Paper
                        sx={{
                        //padding: 4,
                        height: '70vh',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        //backgroundColor: '#338CFB',
                        boxShadow: 'none', // Remove box shadow
                        border: 'none',   // Remove border
                        borderRadius: 0,   // Remove border radius
                        }}
                    >
                        <Container>
                            <Typography variant="h3" gutterBottom>
                                Welcome to BlueCollar Hub
                            </Typography>
                            <Typography variant="h5" paragraph>
                                Connecting skilled artisans with those in need of their services.
                            </Typography>
                            <Button variant="contained" style={{ backgroundColor: '#049353', color: 'white' }}>
                                Get Started
                            </Button>
                            <div style={{ marginTop: 16 }}>
                                <img src="images/play-store1.png" alt="Android Download" style={{ marginRight: 8, height: 80, width: 150 }} />
                                <img src="images/ios-apple1.png" alt="iOS Download" style={{ height: 100, width: 150 }} />
                            </div>
                        </Container>
                    </Paper>
                </Grid>

                {/* Right Section with Image */}
                <Grid item xs={12} sm={6}>
                    <Paper
                        component="header"
                        sx={{
                        backgroundSize: 'cover',
                        backgroundImage: 'url(images/banner-image.jpg)',
                        height: '70vh',
                        }}
                    />
                </Grid>
            </Grid>

            <HowItWorks />
            <Features />
            <Footer />
        </div>
    );
};

export default Home;
