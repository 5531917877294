import React from 'react';
import { Box, Container, Grid, IconButton, Typography, Link } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';




const Footer = () => {
    return (
        <Box
            component="footer"
            sx={{
                backgroundColor: '#333',
                color: 'white',
                py: 3,
                mt: 20, // Add margin-top here
            }}
        >
        <Container>
            <Grid container spacing={3}>
                {/* Lower Section */}
                <Grid item xs={12}>
                    {/* Social Media Links */}
                    <Box mt={3} textAlign="center">
                        <IconButton href="#" target="_blank" rel="noopener" color="inherit">
                            <FacebookIcon />
                        </IconButton>
                        <IconButton href="#" target="_blank" rel="noopener" color="inherit">
                            <TwitterIcon />
                        </IconButton>
                        <IconButton href="#" target="_blank" rel="noopener" color="inherit">
                            <InstagramIcon />
                        </IconButton>
                        <IconButton href="#" target="_blank" rel="noopener" color="inherit">
                            <LinkedInIcon />
                        </IconButton>
                    </Box>

                    {/* Copyright and Links */}
                    <Container>
                        <Typography variant="body2" align="center">
                            &copy; {new Date().getFullYear()} BlueCollar Hub. All rights reserved.
                        </Typography>
                        <Typography variant="body2" align="center">
                            <Link href="#">Privacy Policy</Link> | <Link href="#">Terms of Service</Link>
                        </Typography>
                    </Container>
                </Grid>
            </Grid>
        </Container>
        </Box>
    );
};

export default Footer;
