import React from 'react';
import { Container, Typography, Grid, Paper } from '@mui/material';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import SearchIcon from '@mui/icons-material/Search';
import MessageIcon from '@mui/icons-material/Message';
import WorkIcon from '@mui/icons-material/Work';




const stepData = [
  {
    title: 'Explore Services',
    description:
      'Browse through a wide range of services offered by skilled artisans. Whether you need plumbing, carpentry, electrical work, or more, our platform has you covered.',
    icon: <SearchIcon sx={{ fontSize: 40 }} />,
  },
  {
    title: 'Find the Right Artisan',
    description:
      'Utilize our search and filtering options to find the perfect artisan for your needs. View profiles, reviews, and portfolios to make informed decisions.',
    icon: <HowToRegOutlinedIcon sx={{ fontSize: 40 }} />,
  },
  {
    title: 'Connect and Communicate',
    description:
      "Once you've identified the artisan you want to work with, use our messaging system to communicate directly. Discuss project details, timelines, and any specific requirements.",
    icon: <MessageIcon sx={{ fontSize: 40 }} />,
  },
  {
    title: 'Get Started',
    description:
      'Ready to proceed? Click the "Get Started" button to initiate the service request. Your chosen artisan will be notified, and you can track the progress of your project within the platform.',
    icon: <WorkIcon sx={{ fontSize: 40 }} />,
  },
];

const HowItWorks = () => {
  return (
    <Container>
      <Typography variant="h4" mt={4} mb={3} textAlign="center">
        How It Works
      </Typography>

      <Grid container spacing={3}>
        {stepData.map((step, index) => (
          <Grid item key={index} xs={12} sm={6}>
            <Paper
              sx={{
                padding: 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                minHeight: 200,
                position: 'relative',
                backgroundColor: 'rgba(0, 255, 0, 0.5)', // Green overlay with 50% opacity
                color: 'black', // Set content color to white
              }}
            >
              {step.icon}
              <Typography variant="h6" mt={2} mb={1}>
                {step.title}
              </Typography>
              <Typography variant="body1">
                {step.description}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Typography variant="body1" mt={3} textAlign="center" color="white">
        Our platform is designed to simplify the process of connecting with skilled artisans, ensuring a seamless and efficient experience for both users and service providers.
      </Typography>
    </Container>
  );
};

export default HowItWorks;
