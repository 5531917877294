import React from 'react';
import { AppBar, Toolbar, Button, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';




const Navbar = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <AppBar position="fixed" style={{ backgroundColor: 'white', color: 'black' }}>
            <Toolbar style={{ justifyContent: 'space-between', flexDirection: isMobile ? 'column' : 'row' }}>
                {/* Replace the Typography with your logo */}
                <img src="images/logo.png" alt="BlueCollar Hub Logo" style={{ height: '40px' }} />

                {isMobile ? (
                <div style={{ marginTop: '10px' }}>
                    {/* Mobile menu for small screens */}
                    <Button color="inherit">Login</Button>
                    <Button color="inherit">Services</Button>
                    <Button color="inherit">About Us</Button>
                    {/* Add more links as needed */}
                </div>
                ) : (
                <div>
                    {/* Desktop menu for larger screens */}
                    <Button color="inherit">Login</Button>
                    <Button color="inherit">Services</Button>
                    <Button color="inherit">About Us</Button>
                    {/* Add more links as needed */}
                </div>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
